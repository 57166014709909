import { sendApprovalEmail } from "Apis";
import { ApprovalModal, Checked, ErrorAlert } from "components";
import { propertyTypes } from "constants/AppConstants";
import {
  usePropertyAllowedActions,
  usePropertyCertificateActions,
  usePropertyDetails,
  usePropertyLicenceActions,
  usePropertyUpdate,
  usePropertyWorkOrderActionsContext,
} from "context/propertyContext/PropertyContext";
import useModal from "Hooks/useModal";
import { useCallback, useEffect, useMemo, useState } from "react";

import swal from "sweetalert";
import { formatter, mixPanel } from "utils";
import { Links, ModalV2, Typography } from "v2/components";
import Button from "v2/components/Form/Button/Button";
import AddIcon from "v2/components/Icons/AddIcon";
import Table from "v2/components/Table/Table";
import styles from "./GetSupport.module.css";
const Center = (props) => {
  return (
    <div className={styles.center} id="ViewPackages">
      {props.children}
    </div>
  );
};

const Name = (props) => {
  return <div className={styles.name}>{props.children}</div>;
};

export default function GetSupport(props) {
  const propertyDetails = usePropertyDetails();
  const { openPackageModal } = usePropertyUpdate();
  const { onUpdateItemOrder } = usePropertyLicenceActions();
  const { onAddToOrder } = usePropertyCertificateActions();
  const { readOnly, landlordApproval } = usePropertyAllowedActions();
  const { approveWorkOrder, submitWorkOrder } =
    usePropertyWorkOrderActionsContext();
  const [isApprovalModalOpen, openApprovalModal, closeApprovalModal] =
    useModal();
  const [isEmailResultModalOpen, openEmailResultModal, closeEmailResultModal] =
    useModal();
  const [
    isLandlordApprovalModalOpen,
    openLandlordApprovalModal,
    closeLandlordApprovalModal,
  ] = useModal();
  const [landlordApproved, setLandlordApproved] = useState(false);

  useEffect(() => {
    if (propertyDetails && !propertyDetails.propertyType) {
      props.goHome();
    }
  }, [propertyDetails]);

  const landlordApprovalFunc = () => {
    approveWorkOrder();
    openLandlordApprovalModal();
    setLandlordApproved(true);
  };

  const totalCost = useMemo(() => {
    let calculatedPrice = 0;
    calculatedPrice +=
      propertyDetails.package &&
      propertyDetails.licences
        .filter((value) => value.licenceType !== propertyTypes.licenceS257)
        .some((licence) => licence.ordered)
        ? parseFloat(propertyDetails.package.price)
        : 0;
    calculatedPrice += propertyDetails.planning.reduce((acc, planning) => {
      return acc + (planning.ordered ? parseFloat(planning.cost) : 0);
    }, 0);
    calculatedPrice += propertyDetails.certificates.reduce(
      (acc, certificate) => {
        return (
          acc + (certificate.isSelected ? parseFloat(certificate.price) : 0)
        );
      },
      0
    );
    return formatter(calculatedPrice * 1.2);
  }, [propertyDetails]);
  // const { openSponsorModal } = usePropertySponsorContext();
  // useEffect(() => {
  //   openSponsorModal();
  // }, []);

  const tableData = useMemo(() => {
    const rows = [];
    rows.push({
      className: styles.section,
      name: <Typography as="h2">Licensing</Typography>,
      document: (
        <Center>
          {propertyDetails.package && (
            <Typography as="h3">{`${propertyDetails.package.name} Package - £${propertyDetails.package.price}`}</Typography>
          )}
          <Links as="click" onClick={openPackageModal}>
            View other packages
          </Links>
        </Center>
      ),
      cellProps: {
        document: {
          colSpan: 3,
        },
        costs: {
          hide: true,
        },
        ordered: {
          hide: true,
        },
      },
    });

    rows.push(
      ...propertyDetails.licences.map((licence) => ({
        className: styles.row,
        name: <Name>{licence.licenceType}</Name>,
        document: null,
        costs: (
          <Center>
            <Typography as="h3">{`£${propertyDetails.package?.price}`}</Typography>
          </Center>
        ),
        ordered: (
          <Center>
            <AddIcon
              id="Stonly_AddToOrder"
              isSelected={licence.ordered}
              isDisabled={readOnly}
              toolTip="Add to order"
              selectedToolTip="Remove from order"
              onClick={(data) =>
                onUpdateItemOrder({
                  ...data,
                  itemId: licence.propertyItemId,
                })
              }
            />
          </Center>
        ),
        cellProps: {
          costs: {
            className: styles.vertical,
          },
        },
      }))
    );

    rows.push({
      className: styles.row,
      name: <Name>Council Licensing Fee</Name>,
      document: null,
      costs: (
        <Center>
          <Typography as="h3">---</Typography>
        </Center>
      ),
      ordered: null,
    });

    rows.push({
      className: styles.section,
      name: <Typography as="h2">Planning</Typography>,
      document: null,
      costs: null,
      ordered: null,
    });

    rows.push(
      ...propertyDetails.planning.map((planning) => ({
        className: styles.row,
        name: <Name>{planning.label}</Name>,
        document: null,
        costs: (
          <Center>
            <Typography as="h3">£{planning.cost}</Typography>
          </Center>
        ),
        ordered: (
          <Center>
            <AddIcon
              id="Stonly_AddToOrder"
              isSelected={planning.ordered}
              isDisabled={readOnly}
              toolTip="Add to order"
              selectedToolTip="Remove from order"
              onClick={(data) =>
                onUpdateItemOrder({
                  ...data,
                  itemId: planning.propertyItemId,
                })
              }
            />{" "}
          </Center>
        ),
        cellProps: {
          costs: {
            className: styles.vertical,
          },
        },
      }))
    );

    rows.push({
      className: styles.section,
      name: <Typography as="h2">Certification</Typography>,
      document: null,
      costs: null,
      ordered: null,
    });
    rows.push(
      ...propertyDetails.certificates.map((certificate) => ({
        className: styles.row,
        name: <Name>{certificate.name}</Name>,
        document: null,
        costs: (
          <Center>
            <Typography as="h3">{`£${certificate.price}`}</Typography>
          </Center>
        ),
        ordered: (
          <Center>
            <AddIcon
              id="Stonly_AddToOrder"
              isSelected={certificate.isSelected}
              isDisabled={readOnly}
              toolTip="Add to order"
              selectedToolTip="Remove from order"
              onClick={(data) =>
                onAddToOrder({
                  ...data,
                  certificateId: certificate.id,
                })
              }
            />
          </Center>
        ),
        cellProps: {
          costs: {
            className: styles.vertical,
          },
        },
      }))
    );

    rows.push({
      className: styles.section,
      name: <Typography as="h2">Total (incl. VAT)</Typography>,
      document: null,
      costs: null,
      ordered: <Typography as="h2">{totalCost}</Typography>,
    });

    return rows;
  }, [
    propertyDetails,
    propertyDetails.licences,
    propertyDetails.planning,
    propertyDetails.certificates,
    onAddToOrder,
    onUpdateItemOrder,
    propertyDetails.status,
    propertyDetails.package,
  ]);

  const headers = useMemo(() => {
    return {
      className: styles.row,
      columnNames: [
        { Header: " ", accessor: "name" },
        { Header: "Documents", accessor: "document" },
        {
          Header: "Yuno costings (excl. VAT)",
          accessor: "costs",
          className: styles.vertical,
        },
        { Header: "Add to order", accessor: "ordered" },
      ],
    };
  }, []);
  const submitWorkOrderCallback = useCallback(() => {
    submitWorkOrder({
      packageId: propertyDetails.package.packageId,
      propertyUuid: propertyDetails.uuid,
    });
  }, [propertyDetails.package, propertyDetails.propertyId]);

  const sendEmail = (data) => {
    closeApprovalModal();
    mixPanel.track("Notify landlord", {
      propertyUuid: propertyDetails.uuid,
    });
    sendApprovalEmail(
      { ...data, packageId: propertyDetails.package.packageId },
      propertyDetails.uuid
    )
      .then(() => {
        openEmailResultModal();
      })
      .catch(({ message }) => {
        swal("error", message, "error");
      });
  };

  return (
    <>
      {isApprovalModalOpen && (
        <ApprovalModal
          isOpen={isApprovalModalOpen}
          total={totalCost}
          listDocument={propertyDetails}
          onClose={closeApprovalModal}
          onSuccess={sendEmail}
          propertyId={propertyDetails.propertyId}
        />
      )}
      {isEmailResultModalOpen && (
        <ModalV2 onClose={closeEmailResultModal}>
          <ErrorAlert
            content={<Checked className="icon-checked" />}
            className="alert-message"
            isShow={true}
            text="Your email has been sent!"
            onClose={closeEmailResultModal}
          />
        </ModalV2>
      )}
      {isLandlordApprovalModalOpen && (
        <ModalV2 onClose={closeLandlordApprovalModal}>
          <ErrorAlert
            content={<Checked className="icon-checked" />}
            className="alert-message"
            isShow={true}
            text="Your work order has been approved!"
            onClose={closeLandlordApprovalModal}
          />
        </ModalV2>
      )}
      <>
        {readOnly && !landlordApproval && (
          <div className={styles.bannerContainer}>
            <Checked className="success-checked" />
            <Typography as="h6MapMarker">
              Your work order has been submitted. If you have any questions
              about your work order, send us an email to
            </Typography>
            <Links as="clickSupport" className="link">
              <Typography as="h6MapMarker">support@goyuno.com</Typography>
            </Links>
          </div>
        )}
        {readOnly && landlordApproved && (
          <div className={styles.bannerContainer}>
            <Checked className="success-checked" />
            <Typography as="h6MapMarker">
              Your work order has been submitted. If you have any questions
              about your work order, send us an email to
            </Typography>
            <Links as="clickSupport" className="link">
              <Typography as="h6MapMarker">support@goyuno.com</Typography>
            </Links>
          </div>
        )}

        <div className={styles.container}>
          <Table headers={headers} data={tableData} className={styles.table} />
          <div className={styles.actionContainer}>
            <Typography as="h3">
              Need help getting everything sorted?{" "}
              <Links as="clickSupport">
                Send us your inquiry to support@goyuno.com
              </Links>
            </Typography>
            {propertyDetails.status !== "Work in progress" && (
              <div className={styles.buttonGroup}>
                {!landlordApproval && (
                  <>
                    <Button
                      onClick={openApprovalModal}
                      actionName={"Notify Landlord"}
                      id="Stonly_NotifyLandlord"
                    />
                    <Button
                      id="Stonly_SubmitWorkOrder"
                      onClick={submitWorkOrderCallback}
                      actionName={"Submit work order"}
                    />
                  </>
                )}
                {landlordApproval && !landlordApproved && (
                  <Button
                    onClick={landlordApprovalFunc}
                    actionName={"Approve work order"}
                  />
                )}
              </div>
            )}
          </div>
        </div>
      </>
    </>
  );
}
