import {
  propertyAddVerifiedURL,
  propertyAddUnverifiedURL,
  propertyIdURL,
  addTenancyURL,
  updateTenancyURL,
  sponsoredPropertiesURL,
} from "constants/urls";
import { useAsync } from "Hooks/useAsync";
import { DELETE, GET, POST, PUT, Patch, removeEmptyValue } from "utils";
import mixpanel from "utils/mixPanel";

interface keyable {
  [key: string]: any;
}

type Props = {
  propertyId: string;
  propertyUuid?: string;
};

type UpdatePropertyData = {
  roomUnit: string;
} | null;

type PropertyActions = {
  createProperty: {
    error: ActionError;
    result: Record<string, any>;
    trigger: (addressData: any) => void;
  };
  createPropertyUnverified: {
    error: ActionError;
    result: Record<string, any>;
    trigger: (addressData: any) => void;
  };
  getProperty: APIAction<string, Record<string, any>>;
  getProperties: APIAction<
    void,
    {
      properties: Array<Record<string, any>>;
      pagination: Record<string, any>;
    }
  >;
  getSponsoredProperties: {
    trigger: () => void;
    error: ActionError;
    result: {
      properties: Array<Record<string, any>>;
      pagination: Record<string, any>;
    };
  };
  updateProperty: {
    trigger: (propertyDetails: keyable) => void;
    state: string;
    error: ActionError;
    result: Record<string, any>;
  };
  addTenancy: {
    trigger: () => void;
    state: string;
    error: ActionError;
    result: Record<string, any>;
  };
  updateTenancy: {
    trigger: () => void;
    state: string;
    error: ActionError;
    result: Record<string, any>;
  };
  deleteProperty: {
    trigger: () => void;
    error: ActionError;
    result: string;
  };
};

export default function usePropertyActions(props: Props): PropertyActions {
  const createProperty = async (newPropertyData: Record<string, any>) => {
    if (!!newPropertyData) {
      mixpanel.track("Create Property", newPropertyData);

      return POST(propertyAddVerifiedURL, newPropertyData);
    }
  };

  const createPropertyUnverified = async (
    newPropertyData: Record<string, any>
  ) => {
    if (!!newPropertyData) {
      mixpanel.track("Create Property unverified", newPropertyData);

      return POST(propertyAddUnverifiedURL, newPropertyData);
    }
  };

  const getProperty = async (token: string) => {
    if (props.propertyId) {
      return GET(propertyIdURL(props.propertyId, token));
    }
  };

  const getProperties = async (data: any) => {
    // @HACK(BUGS-284): Removing address attribute until the endpoint is fixed
    // address filtering happens on the front-end for the time being
    // see other @HACK tag
    // Pagination has alse been removed or the front-end can only filter address
    // on the first 10 items
    // if (data.hasOwnProperty("address")) {
    //   delete data.address;
    // }
    // data.perPage = 100;
    // delete data.page;

    return GET(propertyIdURL(), data);
  };

  const getSponsoredProperties = async (data: any) => {
    return GET(sponsoredPropertiesURL(), data);
  };

  const updateProperty = async (onUpdatePropertyData: any) => {
    if (!!onUpdatePropertyData && props.propertyId) {
      const formattedPropertyData = {
        ...onUpdatePropertyData,
      };
      if (onUpdatePropertyData.tenancyEndDate) {
        formattedPropertyData.tenancyEndDate = new Date(
          onUpdatePropertyData.tenancyEndDate
        );
      }
      return PUT(
        propertyIdURL(props.propertyId),
        removeEmptyValue(formattedPropertyData)
      );
    }
  };

  const addTenancy = async (onAddTenancyData: any) => {
    const { propertyUuid, ...otherData } = onAddTenancyData;
    return POST(addTenancyURL(propertyUuid), removeEmptyValue(otherData));
  };

  const updateTenancy = async (onUpdateTenancyData: any) => {
    const { propertyUuid, tenancyId, ...otherData } = onUpdateTenancyData;
    return Patch(
      updateTenancyURL(propertyUuid, tenancyId),
      removeEmptyValue(otherData)
    );
  };

  const deleteProperty = async (deletePropertyId: string) => {
    await DELETE(propertyIdURL(deletePropertyId));
    return { data: deletePropertyId, meta: null };
  };

  const createPropertyPromise = useAsync<
    APIActionResponse<any>,
    APIActionError
  >(createProperty, false);

  const createPropertyUnverifiedPromise = useAsync<
    APIActionResponse<any>,
    APIActionError
  >(createPropertyUnverified, false);

  const getPropertyPromise = useAsync<APIActionResponse<any>, APIActionError>(
    getProperty,
    false
  );
  const getPropertiesPromise = useAsync<APIActionResponse<any>, APIActionError>(
    getProperties,
    false
  );
  const getSponsoredPropertiesPromise = useAsync<
    APIActionResponse<any>,
    APIActionError
  >(getSponsoredProperties, false);

  const updatePropertyPromise = useAsync<
    APIActionResponse<any>,
    APIActionError
  >(updateProperty, false);

  const addTenancyPromise = useAsync<APIActionResponse<any>, APIActionError>(
    addTenancy,
    false
  );

  const updateTenancyPromise = useAsync<APIActionResponse<any>, APIActionError>(
    updateTenancy,
    false
  );

  const deletePropertyPromise = useAsync<
    APIActionResponse<any>,
    APIActionError
  >(deleteProperty, false);

  return {
    createProperty: {
      trigger: createPropertyPromise.execute,
      error: createPropertyPromise.error?.errorDetails,
      result: createPropertyPromise.value?.data,
    },
    createPropertyUnverified: {
      trigger: createPropertyUnverifiedPromise.execute,
      error: createPropertyUnverifiedPromise.error?.errorDetails,
      result: createPropertyUnverifiedPromise.value?.data,
    },
    getProperty: {
      trigger: getPropertyPromise.execute,
      state: getPropertyPromise.status === "pending" ? "loading" : "idle",
      error: getPropertyPromise.error?.errorDetails,
      result: getPropertyPromise.value?.data,
    },
    getProperties: {
      trigger: getPropertiesPromise.execute,
      state: getPropertiesPromise.status === "pending" ? "loading" : "idle",
      error: getPropertiesPromise.error?.errorDetails,
      result: {
        properties: getPropertiesPromise.value?.data || [],
        pagination: getPropertiesPromise.value?.meta || null,
      },
    },
    getSponsoredProperties: {
      trigger: getSponsoredPropertiesPromise.execute,
      error: getSponsoredPropertiesPromise.error?.errorDetails,
      result: {
        properties: getSponsoredPropertiesPromise.value?.data || [],
        pagination: getSponsoredPropertiesPromise.value?.meta || null,
      },
    },
    updateProperty: {
      trigger: updatePropertyPromise.execute,
      state: updatePropertyPromise.status,
      error: updatePropertyPromise.error?.errorDetails,
      result: updatePropertyPromise.value?.data,
    },
    addTenancy: {
      trigger: addTenancyPromise.execute,
      state: addTenancyPromise.status,
      error: addTenancyPromise.error?.errorDetails,
      result: addTenancyPromise.value?.data,
    },
    updateTenancy: {
      trigger: updateTenancyPromise.execute,
      state: updateTenancyPromise.status,
      error: updateTenancyPromise.error?.errorDetails,
      result: updateTenancyPromise.value?.data,
    },
    deleteProperty: {
      trigger: deletePropertyPromise.execute,
      error: deletePropertyPromise.error?.errorDetails,
      result: deletePropertyPromise.value?.data,
    },
  };
}
